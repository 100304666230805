import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import BasicBookmarks from '../components/bookmarks/basicBookmarks'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { Seo } from '../components/seo/Seo'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Bookmarks: React.FC = () => {
  const currentSlug = '/code'
  const currentLocale: AppLocale = GenerateLocales.de
  const seoTitle = 'Mein Bereich'
  const seoDescription = 'Der persönliche Bereich in hoch und weit'
  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })
  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentTitle={seoTitle}
        currentLocale={currentLocale}
        currentSlug={currentSlug}
        otherLocales={[{ locale: GenerateLocales.en, slug: '/en/code/' }]}
        currentPath={currentPagePath}
      >
        <BasicBookmarks />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/code/`} />
}

export default Bookmarks
