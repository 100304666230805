import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useCompareTrainingOffersQuery, useWitQuery } from 'frontendApiGraphqlTypes'
import {
  BookmarkList,
  BookmarkPage,
  CompareableTrainingOffer,
  EditCompareDialogProps,
  exampleBookmarkDialogTranslations,
} from '@hrk/huw-module-library'
import { useUserCodeContext } from '../../context/UserCodeContext'
import { useTranslations } from '../../hooks/useTranslations'
import { useBookmarks } from '../../hooks/useBookmarks'
import { useSiteMetadata } from '../../hooks/useSiteConfig'
import { useLocale } from '../../hooks/useLocale'
import { useQueryParams } from '../../hooks/useQueryParam'
import { useUserCodeDialogContext } from '../../context/UserCodeDialogContext'

const MOBILE_APP_USER_AGENT = 'huw_app'

const BasicBookmarks: React.FC = () => {
  const { userCode } = useUserCodeContext()
  const [compareIds, setCompareIds] = useQueryParams('id')
  const { pageLocale } = useLocale()
  const [witCode, setWitCode] = useState<string | undefined>(undefined)

  const { refetch: refetchWit } = useWitQuery({
    skip: !userCode,
    variables: { userCode: userCode ?? '' },
    onCompleted: (data) => setWitCode(data?.wit?.shortCode),
    onError: (error) => console.error(error),
  })

  const isDesktopApp = window?.navigator?.userAgent !== MOBILE_APP_USER_AGENT
  const pdfEnabled = !!process.env.GATSBY_PDF_ENABLED && process.env.GATSBY_PDF_ENABLED === 'true'

  const {
    bookmarkLists,
    loading,
    called,
    updateBookmarkList,
    createBookmarkList,
    deleteBookmarkList,
    createBookmarkListWithSharedCode,
    shareCode,
    lastModified,
    expiryDate,
  } = useBookmarks()

  const { setUserCodeDialogVisible } = useUserCodeDialogContext()

  useEffect(() => {
    let cancelled = false
    const fetchWit = async () => {
      if (!cancelled && userCode && userCode.length > 0) {
        await refetchWit()
      }
    }
    fetchWit()
    return () => {
      cancelled = true
    }
  }, [refetchWit, userCode])

  const { data } = useCompareTrainingOffersQuery({
    variables: { ids: compareIds?.slice(0, 3) },
    context: { headers: { 'Accept-Language': pageLocale } },
  })

  const {
    common: { time: timeTranslations },
    bookmarks: bookmarkTranslations,
    search: { filterValues: filterValueTranslations },
  } = useTranslations()
  const siteMetadata = useSiteMetadata(pageLocale)

  if (!userCode) {
    return <div>...</div>
  }
  if (!called) {
    return <div>......</div>
  }
  if (loading) {
    return <div>.........</div>
  }

  if (!bookmarkLists) {
    return <div>No bookmarks list</div>
  }

  const findBookmark = (bookmark: { id?: string | null; [key: string]: unknown }) =>
    bookmarkLists
      .flatMap((v) => v.items.map((item) => item))
      .find((bm) => bm.slug === bookmark.pageSlug || bm.slug === '/' + bookmark.pageSlug)

  const compareItems = [
    ...(data?.trainingOffers?.documents.map(({ document: compareItem }) => ({
      ...compareItem,
      isBookmarked: findBookmark(compareItem) !== undefined,
      isFavorited: findBookmark(compareItem)?.isFavorite,
    })) || []),
    ...[null, null, null],
  ].slice(0, 3) as [CompareableTrainingOffer | null, CompareableTrainingOffer | null, CompareableTrainingOffer | null]

  const handleAddCompareItem = (id: string) => {
    if (compareIds.length === 3) {
      // TODO: Need to be moved to module-lib, cf. UserCodeDialogContext -> CodeDialog component
      // toast.error(commonTranslations.errorTooManyCompareElements, { id: 'compare-too-many-elements' })
      return
    }
    if (!compareIds.includes(id)) {
      setCompareIds([...compareIds, id].slice(0, 3))
    } else {
      // TODO: Need to be moved to module-lib
      // toast.error(commonTranslations.errorAlreadyComparing, { id: 'compare-already-comparing' })
      return
    }
  }

  /**
   * Compare dialogs are passed to the compare component because it relies on the query paramters
   */
  const editCompareDialogProps: EditCompareDialogProps = {
    trainingOffers: compareItems,
    onSetCompareIds: setCompareIds,
    navigate,
  }

  return (
    <BookmarkPage
      translations={{ ...bookmarkTranslations.page, ...filterValueTranslations, time: timeTranslations }}
      headline={siteMetadata.config.bookmarkHeadline}
      introduction={siteMetadata.config.bookmarkIntroduction}
      bookmarkLists={bookmarkLists}
      currentProfile={{
        witResult: witCode,
        currentCode: userCode ?? '',
        lastModified,
        expiryDate,
      }}
      editCompareDialogProps={editCompareDialogProps}
      navigate={(url) => {
        return navigate(url + window?.location.search)
      }}
      bookmarkDialogTranslations={exampleBookmarkDialogTranslations}
      onAddComparisonItem={handleAddCompareItem}
      onSaveBookmarkList={function (list: BookmarkList): void {
        if ('id' in list) {
          updateBookmarkList(list)
        } else {
          createBookmarkList(list)
        }
      }}
      onDeleteBookmarkList={function (list: BookmarkList): void {
        deleteBookmarkList(list)
      }}
      generateSharedBookmarkList={function (list: BookmarkList): void {
        createBookmarkListWithSharedCode(list)
      }}
      sharedListCode={shareCode}
      onManageUserCode={() => {
        setUserCodeDialogVisible(true)
      }}
      hideNewCodeButton={isDesktopApp === false}
      pdfEnabled={pdfEnabled}
    />
  )
}

export default BasicBookmarks
